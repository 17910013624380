body {
  background-color: #000;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 80vh; /* Increase to make the logo larger */
  height: auto;
}

.logo {
  width: 100%;
  height: auto;
  transform: scale(2.0); /* Adjust scale as needed */
  transform-origin: center; 
  stroke-linejoin: round;
  stroke-linecap: round;
}

.logo-line {
  fill: none;
  stroke: white;
  stroke-width: 15;
}

.logo-circle {
  fill: white;
}

.logo-text {
  font-family: Arial, sans-serif;
  font-size: 230px; /* Increase font size for visibility */
  fill: white;
  stroke: black;
  stroke-width: 0.02;
}

.icon-container {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 4rem;
}

.icon {
  width: 2.2rem; /* Replaced vh & vw with rem  */
  height: 2.2rem; /* Replaced vh & vw with rem  */
  color: white;
  transition: color 0.3s;
}

.icon:hover {
  color: lightgray;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .logo-container {
    max-width: 70vh; /* Reduce max-width for smaller screens */
  }
  .logo {
    transform: scale(1.5); /* Adjust scale for tablet screens */
  }
  .icon {
    width: 1.7rem;
    height: 1.7rem;
  }
}

@media (max-width: 480px) {
  .logo-container {
    max-width: 40vh; /* Further reduce max-width for mobile */
  }
  .logo {
    transform: scale(1.1); /* Smaller scale for mobile screens */
  }
  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .icon-container {
    margin-top: 3rem;
  }
}

@media (max-width: 400px) {
  .logo {
    transform: scale(.9);
  }
  .icon-container {
    margin-top: 2rem;
  }
}